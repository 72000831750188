<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <frp-logo />
        <h2 class="brand-text text-primary ml-1">FA</h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img :src="imgUrl" fluid alt="Register V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Forgot Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter the email to send OTP
          </b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              v-if="verificationtype == 0"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"  
            >
              <b-row>
                
                <b-col sm="12" class="mt-1">
                  <inputtext
                    class=""
                    name="Email" 
                    :valuex="appData.email"
                    keys="key"
                    @updatedata="(val) => (appData.email = val)"
                    placeholder="Enter Email  "
                    label="Email"
                    validations="required|email"
                  ></inputtext>
                </b-col>
              </b-row>
              <div class="d-flex mt-1">
                <b-button block type="submit" variant="primary">
                  Verify
                </b-button>
              </div>
              <p class="text-center mt-2">
                <b-button v-on:click="logout" type="button">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-button>
              </p>
            </b-form>

            <b-form
              v-if="verificationtype == 1"
              @submit.prevent="handleSubmit(resendOTP)"
              @reset.prevent="resetForm"  
            >
              <h6>OTP has been sent to <span style="font-weight:bold"> {{appData.email || 'noemail'}} </span>. Which exists for only 15 minutes</h6>
              <b-row>
                <b-col sm="12" class="mt-1">
                  <inputtext
                    class=""
                    name="OTP"
                    :valuex="appData.otp"
                    keys="key"
                    @updatedata="(val) => (appData.otp = val)"
                    placeholder="Enter OTP Valued "
                    label="Enter OTP"
                    validations="required|digits:4"
                  ></inputtext>
                </b-col>
              </b-row>
              <div class="d-flex mt-1">
                <b-button block type="submit" variant="primary">
                  Verify
                </b-button>
              </div>
              <!-- <div class="d-flex mt-1">
                <b-button
                  block
                  type="button"
                  @click="resendOTP"
                  variant="outline-primary"
                >
                  Resend OTP
                </b-button>
              </div>  -->
              <p class="text-center mt-2">
                <b-button v-on:click="logout" type="button">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-button>
              </p>
            </b-form>

            <b-form
              v-if="verificationtype == 2"
              @submit.prevent="handleSubmit(ForgotPass)"
              @reset.prevent="resetForm"  
            >
              <h6>Enter  Password <span style="font-weight:bold"> {{appData.resendOTP || 'nootp'}} </span>.</h6>
              <b-row>
                <b-col sm="12" class="mt-1">
                  <inputtext 
                    class=""
                    type="password"
                    name=" Password"
                    :valuex="appData.password"
                    keys="key"
                    @updatedata="(val) => (appData.password = val)"
                    placeholder="Enter Password "
                    label="Enter  Password"
                    validations='required'
                  ></inputtext>

                  <inputtext
                    class=""
                    type="password"
                    name="Forgot Password"
                    :valuex="appData.forgotpassword"
                    keys="key"
                    @updatedata="(val) => (appData.forgotpassword = val)"
                    placeholder="Enter  Confirm Password "
                    label="Enter  Confirm Password"
                    validations='required'
                  ></inputtext>
                </b-col>
              </b-row>
              <div class="d-flex mt-1">
                <b-button block type="submit" variant="primary">
                  Verify
                </b-button>
              </div>
              <!-- <div class="d-flex mt-1">
                <b-button
                  block
                  type="button"
                  @click="resendOTP"
                  variant="outline-primary"
                >
                  Resend OTP
                </b-button>
              </div>-->
              <p class="text-center mt-2"> 
                <b-button v-on:click="logout" type="button">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-button>
              </p>
            </b-form>


          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputtext from "@/views/Component/Input/inputtext.vue";
import axios from "@axios";
import FrpLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { required } from "@validations";
import router from "@/router";

import { initialAbility } from "@/libs/acl/config";
import { useToast } from "vue-toastification/composition";
import store from "@/store/index";
import StoreModule from "./StoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import formValidation from "@core/comp-functions/forms/form-validation";

import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    FrpLogo,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    inputtext,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      required,
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "auth-otp";
    const modulename = "OTP Verification";
    const modulenamesub = "OTP Verification";

    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let key = 0;
    let edittype = 0;
    let verificationtype = ref(0);
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    const blankAppData = {
      otp: "",
      otp_id: "",
      email: "",
      password: "",
      forgotpassword: "",
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));

    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const onSubmit = () => {
      // if (appData.value.email) {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: "Error",
      //       text: "Enter a Valid OTP",
      //       icon: "AlertTriangleIcon",
      //       variant: "warning",
      //     }, 
      //   });
      //   return "";
      // }
      axios
        .post("v1/forgotpassword", { email: appData.value.email })
        .then((response) => {
          if(response.data.success){
            verificationtype.value = 1;
          }
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            appData.value.email = null;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: error.response.data.message || "",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const resendOTP = () => {
      axios
        .post("v1/forgotpasswordverify", { otp: appData.value.otp , email: appData.value.email, forgotpassword: appData.value.forgotpassword})
        .then((response) => {
          if(response.data.success){
            appData.value.otp_id=response.data.data
            verificationtype.value = 2;
            appData.value.password=''
            appData.value.forgotpassword=''
            
          } 
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value.otp = null;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    const ForgotPass = () => {
      if(appData.value.password != appData.value.forgotpassword){

        toast({
            component: ToastificationContent,
            props: {
              title: "Failure",
              text: 'Password do not matc',
              icon: "CheckIcon",
              variant: "success",
            },
          });
          return ;
      }
      axios
        .post("v1/checkpass", { 
          password: appData.value.password ,
          otp_id: appData.value.otp_id
          
        })
        .then((response) => { 
          if(response.data.success){
            router
              .push({
                name: "auth-login",
              })
              .catch(() => {});
          }
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {

            appData.value.password = null;
             appData.value.forgotpassword = null;

            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };

    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          appData.value = response.data.data;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      resendOTP,
      ForgotPass,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      today,
      verificationtype,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      this.$ability.update(initialAbility);
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
